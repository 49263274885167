.MuiTab-root {
    min-width: 72px !important;
}

.MuiListItemIcon-root .MuiSwitch-root {
    transform: scale(0.7);
    margin-left: -12px;
}

.form-compact .MuiSwitch-root {
    transform: scale(0.7);
    padding: 5px 12px;
    height: 24px;
}

.form-compact .MuiSwitch-switchBase {
    padding: 2px 9px;
}

.popup-drawer.popup-drawer {
    max-height: 100vh;
}

.popup-slide.popup-slide {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    overflow: hidden;
}

.MuiListItemIcon-root.MuiListItemIcon-root {
    min-width: 30px;
}

.MuiButton-root.mui-btn-xlg {
    padding: 16px 26px;
    font-size: 18px;
    min-width: 220px;
}

.MuiDrawer-root.MuiDrawer-modal {
    z-index: 6 !important;
}

.MuiDialog-root {
    z-index: 3 !important;
}

.MuiDialog-root.dialog-top {
    z-index: 5 !important;
}

.form-compact .MuiFormControlLabel-label {
    font-size: 12px;
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width:160px;
}

.MuiIconButton-root.inline {
    margin-top: -8px;
    margin-bottom: -8px;
}

.icons-inline {
    margin-left: -8px;
}