.swatch {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 2px;
}

.swatch-color {
    width: 100px;
    height: 100px;
    box-shadow: 2px 2px 4px 4px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0.6rem;
    border-radius: 4px;
}

.swatch-color--light {
    color: black;
}

.swatch-color__name {
    font-size: 16px;
}

.swatch-color__hex {
    font-size: 12px;
}