.type-style-preview {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.type-style-info {
    border-top: solid 1px rgba(255,255,255,0.1);
    color: rgba(255,255,255,0.5);
    padding-top: 4px;
    margin-top: 4px;
}